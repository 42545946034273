export const getComponentByIdInChilds = (
  componentId,
  componentGuid,
  componentNodeId,
  childrenComponents,
) => {
  for (let index in childrenComponents) {
    const component = childrenComponents[index];
    if (
      component.id === componentId &&
      component.guid === componentGuid &&
      component.nodeId === componentNodeId
    ) {
      return component;
    } else if (
      component.childrenComponents &&
      component.childrenComponents.length > 0
    ) {
      const id = getComponentByIdInChilds(
        componentId,
        componentGuid,
        componentNodeId,
        component.childrenComponents,
      );
      if (id !== null) {
        return id;
      }
    }

    if (component.component) {
      const id = getComponentById(
        componentId,
        componentGuid,
        componentNodeId,
        component.component,
      );
      if (id !== null) {
        return id;
      }
    }
  }
  return null;
};

export const getComponentById = (
  componentId,
  componentGuid,
  componentNodeId,
  definition,
) => {
  if (
    definition.id === componentId &&
    definition.guid === componentGuid &&
    definition.nodeId === componentNodeId
  ) {
    return definition;
  } else {
    return getComponentByIdInChilds(
      componentId,
      componentGuid,
      componentNodeId,
      definition.childrenComponents,
    );
  }
};
