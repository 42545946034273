import React, { useCallback } from "react";
import { Box, Collapse, IconButton, useDisclosure } from "@chakra-ui/react";
import PropTypes from "prop-types";
import { FiChevronRight, FiMinus } from "react-icons/fi";

const ComponentNode = ({ structure }) => {
  const { isOpen, onToggle } = useDisclosure();

  const hasChildrens =
    !!structure?.childrenComponents &&
    Array.isArray(structure?.childrenComponents) &&
    structure.childrenComponents.length > 0;

  const handleToggleAction = useCallback(() => {
    if (hasChildrens) {
      onToggle();
    }
  }, [hasChildrens, onToggle]);

  return (
    <Box>
      <IconButton variant="ghost" onClick={handleToggleAction}>
        {hasChildrens ? <FiChevronRight /> : <Box />}
      </IconButton>
      {structure.name || structure?.component?.name} (
      {structure.displayName || structure?.component?.displayName})
      <Collapse in={isOpen} animateOpacity>
        <Box paddingLeft="8px">
          {structure?.childrenComponents?.map((element) => (
            <ComponentNode key={element.name} structure={element} />
          ))}
        </Box>
      </Collapse>
    </Box>
  );
};

ComponentNode.propTypes = {
  onAction: PropTypes.func,
  structure: PropTypes.shape({
    name: PropTypes.string,
    displayName: PropTypes.string,
    component: PropTypes.shape({
      name: PropTypes.string,
      displayName: PropTypes.string,
    }),
    childrenComponents: PropTypes.array,
  }),
};

export default ComponentNode;
