import PropTypes from "prop-types";
import { shimFunctions } from "../../../../../../utils/shims/shims";
import { SingleDatepicker } from "../../../../datepicker";
import { withBaseComponent } from "../../../with-base-component";
import { useResolveProp } from "../../../../../hooks/resolve-prop/use-resolve-prop";

const InputDateComponent = ({
  rootProps,
  value,
  isReadOnly,
  isRequired,
  // eslint-disable-next-line react/prop-types
  isInvalid,
  designerMode,
  onChange = shimFunctions.shimFunction1,
  label = "",
  inputFormat = "dd-MM-yyyy",
  disabled = false,
  variant = "outline",
}) => {
  const { nodeRef, onClick, ...restRootProps } = rootProps;
  const isReadOnlyState = useResolveProp(isReadOnly, true);

  return (
    <SingleDatepicker
      nodeRef={nodeRef}
      {...restRootProps}
      label={label}
      disabled={disabled}
      isReadOnly={designerMode ? false : isReadOnlyState}
      onClick={
        !onClick || onClick === shimFunctions.shimFunction1
          ? undefined
          : onClick
      }
      isRequired={isRequired}
      variant={variant}
      name="date-input"
      date={value}
      dateTimeFormat={inputFormat}
      onDateChange={onChange}
      isInvalid={isInvalid}
    />
  );
};

InputDateComponent.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
    PropTypes.number,
    PropTypes.func,
  ]),
  onChange: PropTypes.func,
  defaultValue: PropTypes.string,
  isReadOnly: PropTypes.bool,
  isRequired: PropTypes.bool,
  isUnique: PropTypes.bool,
  inputFormat: PropTypes.string,
  variant: PropTypes.oneOf(["outline", "ghost"]),
  disabled: PropTypes.bool,
  rootProps: PropTypes.any,
  designerMode: PropTypes.bool,
};

export default withBaseComponent(InputDateComponent);
