// @flow

import React, { useState, useEffect, useMemo, useRef } from "react";
import { Box, Button, Input, Th } from "@chakra-ui/react";
import type { Column, Filter as TFilter } from "../../datagrid-v2";
import { useDatagridContext } from "../../hooks/useDatagridContext";
import { Filter } from "../filter/filter";
import { DatePicker } from "../datepicker/datepicker";
import { ColumnResizer } from "../column-resizer/column-resizer";
import { BsTrash } from "react-icons/bs";
import { format } from "date-fns";
import { DateFilterInput } from "../filter/date-filter-input";
import { NumberFilterInput } from "../filter/number-filter-input";
import { FilterInput } from "../filter/filter-input";
import { filterOptions } from "../filter/filter-options";

type FilterCellType = "date" | "string" | "number" | "datetime";

type CellProps = {
  column: Column,
  width: string,
  type?: FilterCellType,
};

export const FilterCell = (props: CellProps) => {
  const { filter, onFilterValueChange, onFilterTypeChange, column } = props;
  const { type, width } = column;

  return (
    <Th
      width={width || "auto"}
      minWidth={width || "auto"}
      maxWidth={width || "auto"}
      position="relative"
      overflow="hidden"
      padding="0"
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        height="100%"
        padding="4px 16px"
        margin="0"
        gap="5px"
        overflow="hidden"
        position="relative"
      >
        <Box display="flex" alignItems="center">
          {type.includes("date") ? (
            <DateFilterInput
              type={filter?.type}
              format={column.format}
              onChange={(val, idx) =>
                onFilterValueChange(column.name, val, idx)
              }
              values={filter?.values || []}
            />
          ) : type === "number" ? (
            <NumberFilterInput
              type={filter?.type}
              onChange={(val, idx) =>
                onFilterValueChange(column.name, val, idx)
              }
              values={filter?.values || []}
            />
          ) : (
            <FilterInput
              onChange={(val) => onFilterValueChange(column.name, val)}
              value={filter?.values?.[0] || ""}
            />
          )}
          <Filter
            options={filterOptions}
            selected={filter?.type || "contains"}
            onChange={(e) => onFilterTypeChange(column.name, e.target.value)}
          />
        </Box>
      </Box>
      <ColumnResizer column={column} />
    </Th>
  );
};
