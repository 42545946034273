// @flow

import React from 'react'

export const filterOptions = [
  { id: 0, label: "Contains", value: "contains" },
  { id: 1, label: "Not contains", value: "notContains" },
  { id: 2, label: "Equal", value: "equal" },
  { id: 3, label: "Not equal", value: "notEqual" },
  { id: 4, label: "Greater than", value: "greaterThan" },
  { id: 5, label: "Greater or equal", value: "greaterOrEqual" },
  { id: 6, label: "Lower than", value: "lowerThan" },
  { id: 7, label: "Lower or equal", value: "lowerOrEqual" },
  { id: 8, label: "Empty", value: "empty" },
  { id: 9, label: "Not Empty", value: "notEmpty" },
  { id: 10, label: "Between", value: "between" },
  { id: 11, label: "Exclude range", value: "excludeRange" },
] as const;

export type FilterOptionType = typeof filterOptions[number]['value'] ;