import * as React from "react";
import { Table, TableContainer, Box } from "@chakra-ui/react";
import { Header } from "./header/header";
import { Footer } from "./footer/footer";
import { Navigation } from "./navigation/navigation";
import { Body } from "./body/body";
import { useDatagridContext } from "../hooks/useDatagridContext";
import { FilterRow } from "./header/filter-row";
import { DATAGRID_CONTAINER_TESTID } from "../utils/test-ids";
import { useDragContext } from "../hooks/useDragContext";

export const DatagridSimple = () => {
  const { loading, disableFilters, settings, disableHeader, disableFooter } =
    useDatagridContext();

  const { containerRef } = useDragContext();

  return (
    <Box
      overflow="hidden"
      height="100%"
      width="100%"
      maxWidth="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      data-testid={DATAGRID_CONTAINER_TESTID}
    >
      <TableContainer
        height="100%"
        width="100%"
        border="1px solid var(--chakra-colors-gray-200)"
      >
        <Table
          overflowX="auto"
          size="sm"
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          height="100%"
          variant="unstyled"
          width="100%"
          ref={containerRef}
        >
          {!disableHeader && !settings.loading && <Header />}
          {!disableFilters && <FilterRow />}
          {!loading ? (
            <Body />
          ) : (
            <Box
              height="100%"
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              Loading data...
            </Box>
          )}
          {!disableFooter && <Footer />}
        </Table>
      </TableContainer>
      <Navigation />
    </Box>
  );
};
