// @flow
import { useState } from "react";
import { useDatagridContext } from "./useDatagridContext";
import { getId } from "../utils/get-id";

type ReturnType = {
  selectMany: (id: number, shiftPressed: boolean) => void,
  unselectMany: (id: number, shiftPressed: boolean) => void,
};

export const useMultipleSelect = (): ReturnType => {
  const {
    data,
    actions: { onRowSelect },
  } = useDatagridContext();

  const [first, setFirst] = useState();

  const selectMany = (id: number, shiftPressed: boolean) => {
    if (!first || (first && !shiftPressed)) onRowSelect(id, "select");

    setFirst(id);

    if (first && shiftPressed) {
      const firstIndex = data.findIndex((item) => getId(item) === first);
      const currentIndex = data.findIndex((item) => getId(item) === id);
      const start = Math.min(firstIndex, currentIndex);
      const end = Math.max(firstIndex, currentIndex);
      const selectedRows = data
        .slice(start, end + 1)
        .map((item) => getId(item));
      selectedRows
        .filter((id) => id !== first)
        .forEach((id) => onRowSelect(id, "select"));
    }
  };

  const unselectMany = (id: Number, shiftPressed: boolean) => {
    onRowSelect(id, "unselect");
    setFirst(id);

    if (first && shiftPressed) {
      const firstIndex = data.findIndex((item) => getId(item) === first);
      const currentIndex = data.findIndex((item) => getId(item) === id);
      const start = Math.min(firstIndex, currentIndex);
      const end = Math.max(firstIndex, currentIndex);
      const selectedRows = data
        .slice(start, end + 1)
        .map((item) => getId(item));
      selectedRows.forEach((id) => onRowSelect(id, "unselect"));
      setFirst();
    }
  };

  return { selectMany, unselectMany };
};
