// @flow

import * as React from "react";
import { Box, Thead, Tooltip, Tr } from "@chakra-ui/react";
import { useDatagridContext } from "../../hooks/useDatagridContext";
import { HeaderCell } from "../cell/header-cell";
import { ContextMenu } from "@echo/ui";
import { useContextMenu } from "@echo/ui/src/lib/components/context-menu/hooks/useContextMenu";
import { CustomCheckbox } from "../checkbox/checkbox";
import { useRef } from "react";
import {
  DATAGRID_HEADER_ROW_CELL_TESTID,
  DATAGRID_HEADER_ROW_TESTID,
  DATAGRID_HEADER_TESTID,
} from "../../utils/test-ids";
import { getId } from "../../utils/get-id";

export const Header = (): React.Node => {
  const {
    data,
    columns,
    visibleColumns,
    contextMenuActions,
    actions: { onSelectAll },
  } = useDatagridContext();

  const {
    isContextMenuOpened,
    openContextMenu,
    closeContextMenu,
    clickPosition,
  } = useContextMenu();

  const handleChecked = (e) => {
    const isChecked = e.target.checked;
    const idArr = data.map((el) => getId(el));
    if (isChecked) onSelectAll(idArr, "select");
    if (!isChecked) onSelectAll(idArr, "unselect");
  };

  return (
    <Thead
      display="block"
      borderBottom="1px solid black"
      data-testid={DATAGRID_HEADER_TESTID}
    >
      {isContextMenuOpened && contextMenuActions && (
        <Box fontSize="14px">
          <ContextMenu
            isOpen={isContextMenuOpened}
            onClose={closeContextMenu}
            clickPosition={clickPosition}
            actions={contextMenuActions}
          />
        </Box>
      )}
      <Tr
        onContextMenu={openContextMenu}
        data-testid={DATAGRID_HEADER_ROW_TESTID}
      >
        {visibleColumns.map((col) =>
          col.name === "checkbox" ? (
            <HeaderCell
              column={col}
              width={col.width}
              index={col.order}
              key={col.name}
              data-testid={DATAGRID_HEADER_ROW_CELL_TESTID}
            >
              <CustomCheckbox onChange={handleChecked} />
            </HeaderCell>
          ) : (
            <HeaderCell
              column={col}
              width={col.width}
              index={col.order}
              key={col.name}
              data-testid={DATAGRID_HEADER_ROW_CELL_TESTID}
              tooltip
            >
              {col.displayName || col.name}
            </HeaderCell>
          ),
        )}
      </Tr>
    </Thead>
  );
};
