import { getEchoDefinedParent } from "../../components/echo-components/utils/definition-tree-utils/get-echo-defined-parent";
import { showErrorToast } from "../../components/shared/echo-error-toast";
import { calculateProp } from "../../echo-component/function-wrapper/calculate-prop";

export const getComponentProp = (block, componentContext) => ({
  definition: block,
  execute: (staticParams, params, systemParams) =>
    new Promise((resolve, reject) => {
      try {
        if (!componentContext?.state?.__mounted) {
          return;
        }

        const { source } = systemParams;
        let { callerId, callerGuid, callerNodeId } = systemParams;
        let context = componentContext;

        if (source?.context && source?.callerId) {
          callerId = source.callerId;
          context = source.context;
          callerGuid = source.callerGuid;
          callerNodeId = source.callerNodeId;
        }

        const echoDefinedParent = getEchoDefinedParent(
          context.component,
          callerId,
          callerGuid,
          callerNodeId,
        );

        const propDefinition =
          echoDefinedParent.componentProps[staticParams.name];

        if (!propDefinition || propDefinition.type === "PROCESS") {
          // TODO error type
          reject("Get component prop: Bad prop type");
        }

        const value = calculateProp(
          propDefinition,
          context,
          echoDefinedParent.componentProps,
          callerId,
          callerGuid,
          callerNodeId,
        );

        resolve({
          ...staticParams,
          [staticParams.toName || staticParams.name]: value,
        });
      } catch (err) {
        window.console.error(err);
        showErrorToast(err);
        reject(err);
      }
    }),
});
