import {
  getCurrentPart,
  getPartsFormats,
  getPartsRanges,
} from "./get-parts-ranges";
import { getSeparator } from "./get-separator";
import { splitMulti } from "./split-multi";
import { goToNextPart } from "./switch-part";
import { getCursorPos, isDateMatched } from "./validate-character";

export const getDateValue = (e, format) => {
  const date = e.target.value;
  const pos = getCursorPos(e);

  const { usedSeparators, usedSeparatorsObj, current } = getSeparator(
    format,
    date,
    pos,
  );

  const formatParts = getPartsFormats(format);
  const index = splitMulti(date, usedSeparators).length - 1;

  const parts = getPartsRanges(date, usedSeparators);
  const currPart = getCurrentPart(pos, parts);
  const separator = usedSeparatorsObj.find((s) => s.idx >= pos);
  const lastSeparator = usedSeparatorsObj.findLast((s) => s.idx <= pos);

  const shouldDeleteSeparator =
    (lastSeparator && date[date.length - 1] === lastSeparator.char) ||
    (separator && date[date.length - 1] === separator.char);

  if (shouldDeleteSeparator) {
    return date.substring(0, pos);
  }

  const datePart = date.slice(currPart.start, currPart.end);
  const formatPart = formatParts.filter(({ id }) => id <= index)[currPart.id]
    ?.format;

  const isMatched = isDateMatched(datePart, formatPart);
  const isSameOrGreaterLength = datePart.length >= formatPart.length;

  const shouldGoToNextPart = isMatched && isSameOrGreaterLength;

  const isDayPart = formatPart.split("").find((c) => c.toLowerCase() === "d");
  const isYearPart = formatPart.split("").find((c) => c.toLowerCase() === "y");

  if (shouldGoToNextPart) {
    const filtered = formatParts.filter((p) => p.id > currPart.id);
    if (filtered.length === 0) return date;
    goToNextPart(e, format, currPart, date);
  }

  const shouldAddSeparator =
    (isMatched || (isDayPart && datePart.endsWith("1"))) &&
    isSameOrGreaterLength &&
    current;

  if (shouldAddSeparator) {
    return date + current;
  }

  const shouldNotLetTypeMore =
    isYearPart && formatPart.length < datePart.length;

  if (shouldNotLetTypeMore) {
    return date.substring(0, date.length - 1);
  }

  return date;
};
