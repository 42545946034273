// @flow

import * as React from "react";
import { Box, Tbody } from "@chakra-ui/react";
import { useDatagridContext } from "../../hooks/useDatagridContext";
import { useMultipleSelect } from "../../hooks/useMultipleSelect";
import { Row } from "./row";
import {
  DATAGRID_BODY_ROW_TESTID,
  DATAGRID_BODY_TESTID,
} from "../../utils/test-ids";
import { getId } from "../../utils/get-id";
import { useDragContext } from "../../hooks/useDragContext";

export const Body = (): React.ReactNode => {
  const {
    data,
    actions: { onRowClick, onRowDoubleClick },
    loading,
  } = useDatagridContext();

  const { onDragOver } = useDragContext();

  const { selectMany, unselectMany } = useMultipleSelect();

  const handleRowClick = (row: { [key: string]: string }) => {
    if (!onRowClick) return;
    onRowClick(row);
  };

  const handleRowDoubleClick = (row: { [key: string]: string }) => {
    if (!onRowDoubleClick) return;
    onRowDoubleClick(row);
  };

  const handleRowSelect = (e) => {
    const isChecked = e.target.checked;
    const shiftPressed = e.nativeEvent.shiftKey;
    const id = e.target.value;

    if (isChecked) selectMany(id, shiftPressed);
    if (!isChecked) unselectMany(id, shiftPressed);
  };

  if (loading) {
    return (
      <Tbody
        height="100%"
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        data-testid={DATAGRID_BODY_TESTID}
        onDragOver={(e) => onDragOver(e, -1)}
      >
        <Box>Loading data...</Box>
      </Tbody>
    );
  }

  if (!loading && data.length === 0) {
    return (
      <Tbody
        height="100%"
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        data-testid={DATAGRID_BODY_TESTID}
        onDragOver={(e) => onDragOver(e, -1)}
      >
        <Box>No records found.</Box>
      </Tbody>
    );
  }

  return (
    <Tbody
      overflowY="auto"
      overflowX="hidden"
      height="100%"
      display="block"
      data-testid={DATAGRID_BODY_TESTID}
      onDragOver={(e) => onDragOver(e, -1)}
    >
      {data.map((record, idx) => {
        return (
          <Row
            style={{ background: idx % 2 === 0 ? "#eeeeef" : "" }}
            record={record}
            onRowClick={() => handleRowClick(record)}
            onRowDoubleClick={() => handleRowDoubleClick(record)}
            onRowSelect={handleRowSelect}
            key={getId(record)}
            data-testid={DATAGRID_BODY_ROW_TESTID}
          />
        );
      })}
    </Tbody>
  );
};
