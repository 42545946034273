import { useReducer } from "react";

export const useSettingsReducer = () => {
  const initialState = {
    id: null,
    key: null,
    columns: [],
    sorting: [],
    itemsPerPage: 20,
    loading: true,
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case "SET_FIELD": {
        return { ...state, [action.payload.field]: action.payload.value };
      }
      case "SET_STATE": {
        return { ...state, ...action.payload.value };
      }
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const handleId = (value) =>
    dispatch({ type: "SET_FIELD", payload: { field: "id", value } });

  const handleKey = (value) =>
    dispatch({ type: "SET_FIELD", payload: { field: "key", value } });

  const handleColumns = (value) =>
    dispatch({ type: "SET_FIELD", payload: { field: "columns", value } });

  const handleSorting = (value) =>
    dispatch({ type: "SET_FIELD", payload: { field: "sorting", value } });

  const handleItemsPerPage = (value) =>
    dispatch({ type: "SET_FIELD", payload: { field: "itemsPerPage", value } });

  const handlePage = (value) =>
    dispatch({ type: "SET_FIELD", payload: { field: "page", value } });

  const handleFilters = (value) =>
    dispatch({ type: "SET_FIELD", payload: { field: "filters", value } });

  const handleLoading = (value) =>
    dispatch({ type: "SET_FIELD", payload: { field: "loading", value } });

  const setState = (value) =>
    dispatch({ type: "SET_STATE", payload: { value } });

  const setField = (field, value) => {
    switch (field) {
      case "id":
        handleId(value);
        break;
      case "key":
        handleKey(value);
        break;
      case "columns":
        handleColumns(value);
        break;
      case "sorting":
        handleSorting(value);
        break;
      case "itemsPerPage":
        handleItemsPerPage(value);
        break;
      case "page":
        handlePage(value);
        break;
      case "filters":
        handleFilters(value);
        break;
      case "loading":
        handleLoading(value);
        break;
    }
  };

  return { state, setField, setState };
};
