// @flow
import * as React from "react";

import { Box, Text } from "@chakra-ui/react";
import { Pagination } from "./pagination";
import { useDatagridContext } from "../../hooks/useDatagridContext";
import { PageSizeSelect } from "./page-size-select";
import {
  DATAGRID_NAVIGATION_TESTID,
  DATAGRID_NAVIGATION_TOTAL_COUNT_TESTID,
} from "../../utils/test-ids";

export const Navigation = (): React.Node => {
  const { itemsCount } = useDatagridContext();

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      width="100%"
      data-testid={DATAGRID_NAVIGATION_TESTID}
    >
      <Text data-testid={DATAGRID_NAVIGATION_TOTAL_COUNT_TESTID}>
        Total: {itemsCount}
      </Text>
      <Box display="flex" alignItems="center" gap="10px">
        <PageSizeSelect />
        <Pagination />
      </Box>
    </Box>
  );
};
