import { format, isDate } from "date-fns";

const getDateValue = (date) => {
  const day = format(date, "dd");
  const month = format(date, "MM");
  const year = format(date, "yyyy");
  const hour = format(date, "HH");
  const minute = format(date, "mm");
  const dateString = `${year}-${month}-${day}T${hour}:${minute}:00`;

  return dateString;
};

const getValueFromObj = (obj) => {
  if (isDate(obj)) return getDateValue(obj);
  if (Array.isArray(obj)) return getValueFromObj(obj[0]);

  return Object.values(obj)[0];
};

const getValue = async (func) => {
  const res = await func();
  if (typeof res === "object") return getValueFromObj(res);
  return res;
};

export const resolveDefaultValue = async (value) => {
  const type = typeof value;

  if (type === "function") {
    const res = await getValue(value);
    return res;
  }

  return value;
};
