import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types";
import DataGridRowCell from "./row-cell/row-cell";
import { Box, IconButton, useOutsideClick } from "@chakra-ui/react";
import RowCheckbox from "./row-checkbox/row-checkbox";
import { useDataGridRowEvents } from "./hooks/use-row-handlers";
import { getColumns, selectColumnWidth } from "../utils/columns/column-utility";
import { AddIcon } from "@chakra-ui/icons";
import { TfiSave } from "react-icons/tfi";
import { toast } from "react-toastify";
import { showErrorToast } from "../../../../../echo-error-toast";

const DataGridRow = ({
  row = {},
  columnDefinitions,
  selected,
  changeSelection,
  onClick,
  onDoubleClick,
  selectionMode,
  onEdit,
  context,
  enableInlineEdit,
  enforceEditMode,
  style,
  tableDimensions,
  fontSize,
  inlineEditSaveType,
  cellWhiteSpace,
  cellLinesNumber,
}) => {
  const [editState, setEditState] = useState(row);

  useEffect(() => {
    setEditState(row);
  }, [row]);

  const editable = useMemo(
    () => enableInlineEdit && columnDefinitions.some((c) => c.editable),
    [columnDefinitions, enableInlineEdit],
  );

  const [editMode, setEditMode] = useState(false);

  const mode = useMemo(
    () => (enforceEditMode || (editable && editMode) ? "edit" : "display"),
    [editMode, editable, enforceEditMode],
  );

  const eventHandlers = useDataGridRowEvents({
    clickOptions: {
      handlers: {
        onClick: (e) => (onClick ? onClick(row, e) : undefined),
        onDoubleClick: (e) =>
          onDoubleClick ? onDoubleClick(row, e) : undefined,
      },
    },
    editOptions: {
      editMode,
      editable,
      handlers: {},
    },
    editable,
  });

  const handleOnEdit = useCallback(
    (difference) => {
      if ((enforceEditMode || (editMode && editable)) && onEdit) {
        setEditState((prev) => ({ ...prev, ...difference }));
      }
    },
    [editable, enforceEditMode, onEdit, editMode],
  );

  const visibleColumns = columnDefinitions.filter(
    (col) => col.isVisible !== false && col.name !== "__rowColor",
  );

  const columnsWithFixedSizes = getColumns(
    visibleColumns,
    selectionMode,
    selectColumnWidth,
    tableDimensions,
  );

  const checkChanges = (oldState, newState) => {
    const keys = Object.keys(oldState);
    const boolArr = keys.map((k) => oldState[k] !== newState[k]);
    return boolArr.some((el) => el === true);
  };

  const handleRowAdd = () => {
    const isChanged = checkChanges(row, editState);

    if (isChanged) {
      try {
        onEdit(editState);
        toast.success("Saved successfully.");
      } catch (e) {
        showErrorToast(e);
      }
    }

    setEditMode(false);
  };

  const ref = useRef();
  useOutsideClick({
    ref: ref,
    handler: () => {
      if (!editMode) return;
      if (enforceEditMode) return;
      if (inlineEditSaveType === "button") return;

      handleRowAdd();
    },
  });

  return (
    <Box
      ref={ref}
      {...eventHandlers}
      display="table-row"
      as="tr"
      width="100%"
      tabIndex="0"
      style={{ ...style, background: selected ? "#89cff0" : style?.background }}
      borderBottom="1px solid #b9b9b9"
      color={row.__rowColor || "black"}
    >
      {(selectionMode === "single" || selectionMode === "multiple") &&
        (!editMode ? (
          <RowCheckbox
            fontSize={fontSize}
            selected={selected}
            onChange={(newState, options) => {
              changeSelection(row._id, newState, selectionMode, options);
            }}
          />
        ) : (
          <Box
            as="td"
            borderRight="1px solid #b9b9b9"
            display="table-cell"
            fontSize="40px"
            width={selectColumnWidth}
            maxWidth={selectColumnWidth}
          >
            <IconButton
              icon={<TfiSave fontSize="14px" className="datagrid-save-icon" />}
              variant="ghost"
              height="100%"
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
              type="button"
              onClick={handleRowAdd}
            />
          </Box>
        ))}
      {selectionMode === "empty" && (
        <Box
          as="td"
          borderRight="1px solid #b9b9b9"
          display="table-cell"
          fontSize="40px"
          width={selectColumnWidth}
          maxWidth={selectColumnWidth}
        >
          <IconButton
            icon={<AddIcon fontSize="12px" />}
            variant="ghost"
            height="100%"
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            type="button"
            onClick={handleRowAdd}
          />
        </Box>
      )}
      {columnsWithFixedSizes.map((columnDef, index) => (
        <React.Fragment key={`row-cell-${row._id}-${index}`}>
          <DataGridRowCell
            onClick={
              !editMode && columnDef.editable
                ? () => setEditMode(true)
                : undefined
            }
            width={columnDef.pxWidth < 0 ? 1 : columnDef.pxWidth}
            name={columnDef.name}
            editName={columnDef.editName}
            noOfLines={cellLinesNumber}
            fontSize={fontSize}
            whiteSpace={cellWhiteSpace}
            context={context}
            row={editState}
            onEdit={handleOnEdit}
            onRowEdit={onEdit}
            editDataSource={columnDef?.editDataSource}
            mode={mode}
            format={columnDef.format ? columnDef.format : null}
            editable={columnDef.editable}
            type={
              columnDef.formatType && columnDef.formatType !== ""
                ? columnDef.formatType
                : columnDef.type
            }
            options={{ align: columnDef?.align }}
            editType={
              columnDef.editType ||
              (columnDef.formatType ? columnDef.formatType : columnDef.type)
            }
          />
        </React.Fragment>
      ))}
    </Box>
  );
};

DataGridRow.propTypes = {
  row: PropTypes.any,
  selected: PropTypes.bool,
  changeSelection: PropTypes.func.isRequired,
  selectionMode: PropTypes.oneOf(["single", "multiple", "none"]),
  columnDefinitions: PropTypes.array,
  onClick: PropTypes.func,
  onAdd: PropTypes.func,
  onDoubleClick: PropTypes.func,
  onEdit: PropTypes.func,
  context: PropTypes.any,
  enforceEditMode: PropTypes.bool,
  enableInlineEdit: PropTypes.bool,
  inlineEditSaveType: PropTypes.oneOf(["button", "blur"]),
  style: PropTypes.object,
  tableDimensions: PropTypes.object,
  fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  cellWhiteSpace: PropTypes.string,
  cellLinesNumber: PropTypes.string,
};

export default DataGridRow;
