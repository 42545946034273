import React from "react";
import { IconButton } from "@chakra-ui/react";
import PropTypes from "prop-types";
import { MdOutlineArrowDropDown, MdOutlineArrowDropUp } from "react-icons/md";
import { useInputContext } from "../hooks/use-input-context";

export const StepperBtn = (props) => {
  const { type, onClick, isDisabled } = props;
  const { inputRef } = useInputContext();

  const handleClick = (e) => {
    e.preventDefault();
    if (document.activeElement !== inputRef.current) {
      inputRef.current.focus();
    }
    onClick();
  };

  return (
    <IconButton
      isDisabled={isDisabled}
      variant="ghost"
      aria-label={type}
      borderRadius="0"
      icon={
        type === "increment" ? (
          <MdOutlineArrowDropUp
            style={{
              padding: "0",
              margin: "0",
              fontSize: "16px",
            }}
          />
        ) : (
          <MdOutlineArrowDropDown
            style={{
              padding: "0",
              margin: "0",
              fontSize: "16px",
            }}
          />
        )
      }
      onMouseDown={handleClick}
      minWidth="0"
      height="50%"
      width="100%"
      padding="0"
      margin="0"
    />
  );
};

StepperBtn.propTypes = {
  type: PropTypes.oneOf(["increment", "decrement"]),
  onClick: PropTypes.func,
  isDisabled: PropTypes.bool,
};
