import { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Stack } from "@chakra-ui/react";
import PropertiesTable from "../properties/properties-table/properties-table";
import {
  getBaseComponentsList,
  getComponentProperties,
} from "../../../services/components-service";
import { toast } from "react-toastify";
import EchoStateValueCell from "../properties/component-properties-table/echo-state-value-cell";
import CodeEditorValueCell from "../properties/properties-table/value-cell/code-editor-value-cell";
import {
  BlockTypeQueryEnum,
  getBlockList,
} from "../../../services/diagram-service";

const sortOptionsByLabel = (a, b) => {
  const aLabel = a.label.toUpperCase();
  const bLabel = b.label.toUpperCase();

  if (aLabel < bLabel) {
    return -1;
  }
  if (aLabel > bLabel) {
    return 1;
  }
  return 0;
};

const getPropOptionsPromise = (optionKey, propertyKey) => {
  switch (optionKey) {
    case "email-label":
      return {
        key: propertyKey,
        promise: new Promise((resolve) => {
          resolve([{ name: "READ" }, { name: "UNREAD" }]);
        }),
        propType: "combo",
        mapFunction: (r) => ({ value: r.name, label: r.name }),
      };
    case "component-api-method":
      return {
        key: propertyKey,
        promise: new Promise((resolve) => {
          resolve([
            { name: "getColumns" },
            { name: "getData" },
            { name: "getSelectedRows" },
            { name: "refreshData" },
          ]);
        }),
        propType: "combo",
        mapFunction: (r) => ({ value: r.name, label: r.name }),
      };
    case "component-route":
      return {
        key: propertyKey,
        promise: new Promise((resolve, reject) => {
          getBaseComponentsList()
            .then((result) => {
              return resolve([
                { name: "diagramDesigner", displayName: "Diagram designer" },
                ...result,
              ]);
            })
            .catch(reject);
        }),
        propType: "combo",
        mapFunction: (r) => ({ value: r.name, label: r.name }),
      };
    case "component-id":
      return {
        key: propertyKey,
        promise: new Promise((resolve, reject) => {
          getBaseComponentsList()
            .then((result) => resolve(result))
            .catch(reject);
        }),
        propType: "combo",
        mapFunction: (r) => ({ value: r.id, label: r.name }),
      };
    case "block":
      return {
        key: propertyKey,
        promise: getBlockList(
          BlockTypeQueryEnum.Public |
            BlockTypeQueryEnum.Frontend |
            BlockTypeQueryEnum.Backend,
          [0],
        ),
        propType: "combo",
        mapFunction: (r) => ({ value: r.id, label: r.name }),
      };
    default:
      throw new Error(
        `Error. Cannot load options for property '${propertyKey}. Bad option key.'`,
      );
  }
};

const getPropertiesOptions = (schemaObject, onAction) =>
  new Promise((resolve, reject) => {
    const promiseArray = [];
    const defaultModel = {};
    let resultProperties = [];

    if (schemaObject && schemaObject.properties) {
      resultProperties = Object.keys(schemaObject.properties).map((key) => {
        if (
          schemaObject.properties[key].default ||
          schemaObject.properties[key].default === null
        ) {
          defaultModel[key] = schemaObject.properties[key].default;
        }

        if (
          schemaObject.advanced &&
          schemaObject.advanced[key] &&
          (schemaObject.advanced[key].echoType === "query" ||
            schemaObject.advanced[key].echoType === "component-route" ||
            schemaObject.advanced[key].echoType === "component-id" ||
            schemaObject.advanced[key].echoType === "block" ||
            schemaObject.advanced[key].echoType === "component-api-method" ||
            schemaObject.advanced[key].echoType === "email-label")
        ) {
          promiseArray.push(
            getPropOptionsPromise(schemaObject.advanced[key].echoType, key),
          );
        } else if (
          schemaObject.advanced &&
          schemaObject.advanced[key] &&
          schemaObject.advanced[key]?.echoType === "postgresql"
        ) {
          return {
            propName: key,
            propType: "postgresql-editor",
            onAction: onAction,
          };
        } else if (
          schemaObject.advanced &&
          schemaObject.advanced[key] &&
          schemaObject.advanced[key].echoType === "process"
        ) {
          return { propName: key, propType: "process" };
        } else if (
          schemaObject.advanced &&
          schemaObject.advanced[key] &&
          schemaObject.advanced[key].echoType === "code"
        ) {
          return { propName: key, propType: "code" };
        } else if (schemaObject.properties[key].type === "boolean") {
          return {
            propName: key,
            propType: "bool",
          };
        } else if (schemaObject.properties[key].enum) {
          return {
            propName: key,
            propType: "combo",
            options: schemaObject.properties[key].enum.map((enumValue) => ({
              value: enumValue,
              label: enumValue.toString(),
            })),
          };
        }
        return {
          propName: key,
          propType: "input", // TODO change to text-editor
        };
      });
    }

    const promises = promiseArray.map((p) => p.promise);

    Promise.all(promises)
      .then((resultArray) => {
        resultArray.forEach((resultItem, index) => {
          const promiseDefItem = promiseArray[index];
          if (promiseDefItem.key) {
            const destinationPropItem = resultProperties.find(
              (p) => p.propName === promiseDefItem.key,
            );
            if (destinationPropItem) {
              destinationPropItem.options = resultItem
                .map(promiseDefItem.mapFunction)
                .sort(sortOptionsByLabel);
              destinationPropItem.propType = promiseDefItem.propType;
            }
          }
        });
        resolve({ options: resultProperties, defaultModel });
      })
      .catch(reject);
  });

const getPropType = (schemaObject, propName) => {
  if (
    schemaObject &&
    schemaObject.properties &&
    schemaObject.properties[propName] &&
    schemaObject.advanced &&
    schemaObject.advanced[propName]
  ) {
    return schemaObject.advanced[propName].echoType;
  }

  return typeof undefined;
};

const mapProperties = (props) => {
  const result = {};
  (props || []).forEach((prop) => {
    result[prop.propName] = { type: prop.propType };
  });
  return result;
};

const ParamsSetting = ({
  parametersSchema = "",
  model,
  onUpdate,
  onAction,
}) => {
  const [propertiesOptions, setPropertiesOptions] = useState([]);

  const handleOnModelChange = useCallback(
    async (newModel, propName, value) => {
      let schema = parametersSchema;

      const propType = getPropType(parametersSchema, propName);
      if (typeof onUpdate === "function") {
        switch (propType) {
          case "component-route": {
            let props;
            try {
              props = await getComponentProperties({ name: value });
            } catch {
              props = [];
            }
            schema = {
              ...parametersSchema,
              properties: {
                ...parametersSchema.properties,
                componentProps: {
                  type: "object",
                  properties: mapProperties(props),
                },
              },
            };
          }
        }
      }

      if (onUpdate) {
        onUpdate(newModel, schema);
      }
    },
    [onUpdate, parametersSchema],
  );

  useEffect(() => {
    try {
      getPropertiesOptions(
        typeof parametersSchema === "string"
          ? JSON.parse(parametersSchema)
          : parametersSchema,
        onAction ?? (() => {}),
      )
        .then(({ options, defaultModel }) => {
          Promise.all([
            setPropertiesOptions(options),
            onUpdate({ ...defaultModel, ...model }),
          ]);
        })
        .catch((err) => {
          window.console.error(err);
          toast.error("Error. Cannot load combo options for parameters table.");
        });
    } catch {
      setPropertiesOptions([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parametersSchema]);

  return propertiesOptions.length > 0 ? (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        alignContent: "center",
        width: "100%",
        height: "100%",
        padding: "0px 20px",
        overflow: "hidden",
        boxSizing: "border-box",
      }}
    >
      <Stack spacing="30px" width="100%">
        <div
          style={{
            width: "100%",
            height: "auto",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <h3>Parameters</h3>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              alignContent: "center",
            }}
          ></div>
        </div>

        <div style={{ width: "90%" }}>
          <PropertiesTable
            options={propertiesOptions}
            model={model}
            onModelChange={handleOnModelChange}
            customTypes={[
              {
                name: "process",
                component: ({ propValue, onChange, propName }) => (
                  <EchoStateValueCell
                    propName={propName}
                    propValue={propValue}
                    onChange={onChange}
                  />
                ),
              },
              {
                name: "code",
                component: ({ propValue, onChange, propName }) => (
                  <CodeEditorValueCell
                    propName={propName}
                    propValue={propValue}
                    onChange={onChange}
                  />
                ),
              },
            ]}
          />
        </div>
      </Stack>
    </div>
  ) : (
    "There are no options to set"
  );
};

ParamsSetting.propTypes = {
  parametersSchema: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  model: PropTypes.any,
  onUpdate: PropTypes.func,
  onAction: PropTypes.func,
};

export default ParamsSetting;
