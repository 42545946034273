import PropTypes from "prop-types";
import "../../styles/main-component/user-menu.css";
import { IconButton, useDisclosure } from "@chakra-ui/react";
import { FiUser } from "react-icons/fi";
import { Box, HStack } from "@chakra-ui/layout";
import UserSelectListModal from "../user/user-change-dialog";
import { useHistory } from "../echo-router/hooks";
import { Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/menu";
import ChangeUserContextModal from "./change-user-context-modal";
import { toast } from "react-toastify";
import { GET } from "../../services/base-fetch";

const UserHeaderComponent = ({ userName, isSuperuser }) => {
  const history = useHistory();
  const changePasswordModal = useDisclosure();
  const changeContextModal = useDisclosure();

  const ping = async () => {
    try {
      await toast.promise(
        // eslint-disable-next-line no-undef
        GET(process.env.API_URL + "EchoVersion/getEchoVersion"),
        {
          pending: "Sprawdzanie połączenia.",
          error: "Występują problemy w połączeniu z serwerem.",
          success: "Połączenie z serwerem jest stabilne.",
        },
      );
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Box>
      <HStack>
        <Menu>
          <MenuButton
            variant="outline"
            as={IconButton}
            aria-label="Options"
            icon={<FiUser color="white" />}
            color="white"
          />
          <MenuList>
            <MenuItem
              onClick={() => {
                history.push("/");
              }}
            >
              Strona główna
            </MenuItem>
            <MenuItem
              onClick={() => {
                changePasswordModal?.onOpen();
              }}
            >
              Zmień hasło
            </MenuItem>
            <MenuItem onClick={ping}>Sprawdź połączenie z serwerem</MenuItem>
            {isSuperuser && (
              <MenuItem
                onClick={() => {
                  changeContextModal?.onOpen();
                }}
              >
                Change user context
              </MenuItem>
            )}
          </MenuList>
        </Menu>
        <Box
          color="white"
          fontWeight="normal"
          maxWidth="30vw"
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          {userName}
        </Box>
      </HStack>
      <UserSelectListModal {...changePasswordModal} />
      <ChangeUserContextModal {...changeContextModal} />
    </Box>
  );
};

UserHeaderComponent.propTypes = {
  userName: PropTypes.string.isRequired,
  isSuperuser: PropTypes.bool.isRequired,
};

export default UserHeaderComponent;
