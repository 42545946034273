import PropTypes from "prop-types";
import { createContext, useEffect, useReducer } from "react";
import { ERROR_SEVERITY } from "./logger";
import { getLogSeverity } from "../services/log-service";

export const LOG_ACTION_TYPE = {
  UPDATE_SEVERITY: 0,
};

const logStateReducer = (prevState, action) => {
  switch (action.type) {
    case LOG_ACTION_TYPE.UPDATE_SEVERITY:
      return { ...prevState, severity: action.payload };
    default:
      return prevState;
  }
};

export const LogContext = createContext();

const LogProvider = ({
  initialSeverity = ERROR_SEVERITY.ERROR,
  isLoggedIn = false,
  children,
}) => {
  const [contextState, dispatch] = useReducer(logStateReducer, {
    logSeverity: initialSeverity,
  });

  useEffect(() => {
    if (isLoggedIn) {
      const fetchLogSeverity = async () => {
        const logSeverity = await getLogSeverity();

        dispatch({
          type: LOG_ACTION_TYPE.UPDATE_SEVERITY,
          payload: logSeverity,
        });
      };

      fetchLogSeverity();
    }
  }, [isLoggedIn]);

  return (
    <LogContext.Provider value={contextState}>{children} </LogContext.Provider>
  );
};

LogProvider.propTypes = {
  initialSeverity: PropTypes.oneOf(Object.keys(ERROR_SEVERITY)),
  isLoggedIn: PropTypes.bool,
  children: PropTypes.node,
};

export default LogProvider;
