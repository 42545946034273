import getToken from "../../services/token-service";

const fixSystemUrl = (systemUrl) => {
  if (systemUrl.slice(-1) === "/") {
    return systemUrl;
  } else {
    return `${systemUrl}/`;
  }
};

const getUrl = (url) => {
  if (!url || typeof url !== "string") {
    // eslint-disable-next-line no-undef
    return fixSystemUrl(process.env.API_URL) + `File/uploadToDatabase`;
  } else if (url.includes("http")) {
    return url;
  } else if (url.slice(0, 1) === "/") {
    // eslint-disable-next-line no-undef
    return fixSystemUrl(process.env.API_URL) + url.slice(1);
  } else {
    // eslint-disable-next-line no-undef
    return fixSystemUrl(process.env.API_URL) + url;
  }
};

const checkType = (file) => {
  if ("File" in window && file instanceof File) {
    return "file";
  } else if (
    file &&
    typeof file === "object" &&
    file.value &&
    typeof file.value === "string"
  ) {
    return "base64";
  } else {
    return "unsupported";
  }
};

const base64ToFile = ({ name, value }) =>
  new Promise((resolve, reject) => {
    fetch(value)
      .then((res) => res.blob())
      .then((blob) => {
        resolve(
          new File([blob], name, {
            type: value.match(/^data:(.+);base64/)?.[1],
          }),
        );
      })
      .catch(reject);
  });

const storeFileObject = (file, endpoint, processInput) => {
  const { storeProcess, ...input } = processInput;

  var data = new FormData();
  data.append("file", file);
  data.append("processInput", JSON.stringify(input || {}));
  data.append("processId", storeProcess?.id);

  return fetch(getUrl(endpoint), {
    method: "POST",
    headers: { authorization: `Bearer ${getToken()}` },
    body: data,
  });
};

const getPageQueryParams = (uriQueryParams) => {
  const params = [];
  if (uriQueryParams) {
    for (let paramKey of Object.keys(uriQueryParams)) {
      params.push({ name: paramKey, value: uriQueryParams[paramKey] });
    }
  }
  return params;
};

export const storeFile = (block, componentContext) => ({
  definition: block,
  execute: (staticParams, params) =>
    new Promise((resolve, reject) => {
      const file =
        staticParams.file ||
        (Array.isArray(params) && params.length ? params[0] : undefined);

      const endpoint = staticParams.endpoint || "File/upload";
      const fileType = checkType(file);

      if (fileType === "unsupported") {
        reject("File type is not supported");
      }

      const queryParamsArray = getPageQueryParams(
        componentContext?.page?.queryParams,
      );

      const staticParamsWithSession = {
        ...staticParams,
        sessionData: {
          userId: parseFloat(
            componentContext.userContext.contextId ||
              componentContext.userContext.userId,
          ),
          companyId: parseFloat(componentContext.userContext.companyId),
          origin: window.origin,
          dataObjectId:
            staticParams.___relatedObjectId ||
            componentContext.component?.relatedObjectId ||
            (isNaN(
              (queryParamsArray || []).find((p) => p.name === "ObjectId")
                ?.value,
            )
              ? null
              : Number(
                  (queryParamsArray || []).find((p) => p.name === "ObjectId")
                    ?.value,
                )),
        },
      };

      switch (fileType) {
        case "base64":
          base64ToFile(file)
            .then((fileObject) =>
              storeFileObject(fileObject, endpoint, staticParamsWithSession)
                .then((response) => {
                  if (response.ok) {
                    resolve(staticParamsWithSession);
                  } else {
                    const isJson = response.headers
                      .get("content-type")
                      ?.includes("application/json");
                    if (isJson) {
                      response.json().then(reject).catch(reject);
                    } else {
                      reject(response);
                    }
                  }
                })
                .catch(reject),
            )
            .catch(reject);
          break;
        case "file":
          storeFileObject(file, endpoint, staticParamsWithSession)
            .then((response) => {
              if (!response.ok) {
                const isJson = response.headers
                  .get("content-type")
                  ?.includes("application/json");
                if (isJson) {
                  response.json().then(reject).catch(reject);
                } else {
                  reject(response);
                }
              } else {
                resolve(staticParamsWithSession);
              }
            })
            .catch(reject);
          break;
        default:
          reject("File type unsupported");
          break;
      }
    }),
});
