// @flow

import * as React from "react";
import type { Column } from "../../datagrid-v2";
import { Cell } from "../cell/cell";
import { format } from "date-fns";
import { Checkbox } from "@chakra-ui/react";
import { getNumberFormat } from "../../utils/get-number-format";
import { isDate } from "date-fns";

type DisplayCellProps = {
  column: Column,
  value: string,
  onClick: (column: Column) => void,
};

export const DisplayCell = (props: DisplayCellProps) => {
  const { column, value, onClick, style, cellLinesNumber } = props;

  return (
    <Cell
      onClick={() => onClick(column)}
      column={column}
      width={column.width}
      key={`${column.name}`}
      style={{
        ...style,
        textAlign: column?.align,
      }}
      cellLinesNumber={cellLinesNumber}
    >
      {column.type === "boolean" ? (
        <Checkbox isChecked={getDisplayValue(column, value)} isReadOnly />
      ) : (
        getDisplayValue(column, value)
      )}
    </Cell>
  );
};

const getCondition = (column, value) => {
  const { type, editType } = column;

  if (editType && editType !== "") return editType;
  if (!editType && type && type !== "") return type;
  return "string";
};

const getNumberValue = (value, format) => {
  const { precision, separator } = getNumberFormat(format);
  if (!value && value !== 0) return "";

  return parseFloat(value)
    .toFixed(precision)
    .replace(".", separator)
    .replace(",", separator);
};

const getDateValue = (value, formatStr) => {
  if (!value) return "";
  try {
    const date = new Date(value);
    const formatted = format(date, formatStr || "dd-MM-yyyy");
    return formatted;
  } catch (e) {
    console.error(e);
    return "";
  }
};

const getBoolValue = (value) => {
  if (!value) return false;
  if (value === 0 || value === false || value === "false") return false;
  if (value === 1 || value === true || value === "true") return true;

  return true;
};

const getComboValue = (value, options) => {
  if (!options || (options && options.length < 1)) return value;
  const f = options?.find((opt) => {
    const [optValue, optLabel] = Object.values(opt);
    return optValue === value;
  });

  if (f) return Object.values(f)[1];
  return value;
  s;
};

const getDisplayValue = (column, value) => {
  const { format } = column;
  const condition = getCondition(column, value);

  switch (condition) {
    case "number":
      return getNumberValue(value, format);
    case "date":
    case "datetime":
      return getDateValue(value, format);
    case "boolean":
    case "checkbox":
      return getBoolValue(value);
    case "combo":
      return getComboValue(value, column.editDataSource);
    case "string":
    default:
      return value;
  }
};
