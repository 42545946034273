// @flow

import { Box, Button, Tfoot, Tr } from "@chakra-ui/react";
import React, { useState } from "react";
import { Cell } from "../cell/cell";
import type { Column } from "../../datagrid-v2";
import { useDatagridContext } from "../../hooks/useDatagridContext";
import {
  DATAGRID_FOOTER_CELL_TESTID,
  DATAGRID_FOOTER_TESTID,
} from "../../utils/test-ids";
import { getResultData } from "../../utils/row-utils";
import { BoolEditCell } from "../body/edit-cell/bool-edit-cell";
import { ComboEditCell } from "../body/edit-cell/combo-edit-cell";
import { InputDateEditCell } from "../body/edit-cell/input-date-edit-cell";
import { InputNumericEditCell } from "../body/edit-cell/input-numeric-edit-cell";
import { InputEditCell } from "../body/edit-cell/input-edit-cell";

export const Footer = (): React.Node => {
  const {
    enableAdding,
    visibleColumns,
    actions: { onRowAdd },
  } = useDatagridContext();

  const [rowState, setRowState] = useState({});

  const handleChange = async (value, column) => {
    const { name } = column;
    setRowState((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (column.onChangeEvent) {
      const { onChangeEvent } = column;
      const state = { ...rowState, [name]: value };
      const res = await onChangeEvent(state);
      const result = getResultData(res);
      setRowState((prev) => {
        return { ...prev, ...result };
      });
    }
  };

  const handleClick = async () => {
    if (onRowAdd) {
      try {
        await onRowAdd(rowState);
        setRowState({});
      } catch (e) {
        console.error("Error while adding new row.");
      }
    }
  };

  return (
    <Tfoot
      display={"block"}
      borderTop={"1px solid"}
      data-testid={DATAGRID_FOOTER_TESTID}
    >
      <Tr>
        {visibleColumns.map((col) =>
          col.name === "checkbox" ? (
            <Cell
              column={col}
              width={col.width}
              key={`${col.name}-footer`}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              data-testid={DATAGRID_FOOTER_CELL_TESTID}
            >
              {enableAdding && (
                <Button
                  variant="unstyled"
                  onClick={handleClick}
                  height="100%"
                  width="100%"
                >
                  +
                </Button>
              )}
            </Cell>
          ) : (
            <FooterCell
              column={col}
              key={col.name}
              onChange={handleChange}
              value={rowState[col.name] || ""}
              data-testid={DATAGRID_FOOTER_CELL_TESTID}
            />
          ),
        )}
      </Tr>
    </Tfoot>
  );
};

type CellProps = { column: Column };
type InputCellProps = { onChange: () => void, value: string };
type FooterCellProps = CellProps & Partial<InputCellProps>;

const FooterCell = (props: FooterCellProps) => {
  const { enableAdding } = useDatagridContext();
  const { column, onChange, value } = props;

  return enableAdding ? (
    <InputCell column={column} onChange={onChange} value={value} />
  ) : (
    <DisplayCell column={column} />
  );
};

const DisplayCell = (props: CellProps) => {
  const { column } = props;
  return (
    <Cell column={column} width={column.width}>
      <Box />
    </Cell>
  );
};

const InputCell = (props: CellProps & InputCellProps) => {
  const { column, onChange, value } = props;

  const handleChange = (value) => {
    if (!value && value === "") onChange(null, column);
    else onChange(value, column);
  };

  return (
    <Cell column={column} width={column.width}>
      <CondiionCell onChange={handleChange} column={column} value={value} />
    </Cell>
  );
};

const getConditions = (type, editType) => {
  const isString = type === "string";
  const isNumber = type === "number";
  const isDate = type === "datetime";
  const isBool = type === "boolean";
  const isCombo = editType === "combo";

  return { isString, isNumber, isDate, isBool, isCombo };
};

const CondiionCell = (props) => {
  const { column, ...inputProps } = props;
  const { type, editType, editDataSource: options, format } = column;

  const { isNumber, isDate, isBool, isCombo } = getConditions(type, editType);

  if (isNumber) return <InputNumericEditCell {...inputProps} />;
  if (isDate) return <InputDateEditCell dateFormat={format} {...inputProps} />;
  if (isCombo) return <ComboEditCell options={options} {...inputProps} />;
  if (isBool) return <BoolEditCell {...inputProps} />;

  return <InputEditCell {...inputProps} />;
};
