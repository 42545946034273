// @flow

import * as React from "react";
import type { Column } from "../../datagrid-v2";
import { Cell } from "../cell/cell";
import { InputEditCell } from "./edit-cell/input-edit-cell";
import { InputDateEditCell } from "./edit-cell/input-date-edit-cell";
import { InputNumericEditCell } from "./edit-cell/input-numeric-edit-cell";
import { BoolEditCell } from "./edit-cell/bool-edit-cell";
import { ComboEditCell } from "./edit-cell/combo-edit-cell";

type InputCellProps = {
  column: Column,
  value: string,
  onChange: (value: any, columnName: string) => void,
  autoFocus: boolean,
};

export const EditCell = (props: InputCellProps): React.Node => {
  const { column, onChange, value, autoFocus, style } = props;
  const { type, editDataSource: options } = column;

  const handleChange = (value) => {
    if (value === "") onChange(null, column);
    else onChange(value, column);
  };

  return (
    <Cell
      column={column}
      width={column.width}
      key={`${column.name}`}
      style={style}
    >
      <ConditionCell
        onChange={handleChange}
        column={column}
        value={value}
        autoFocus={autoFocus}
      />
    </Cell>
  );
};

const getCondition = (type, editType) => {
  if (editType && editType !== "") return editType;
  if (!editType && type && type !== "") return type;
  return "string";
};

const ConditionCell = (props) => {
  const { column, ...inputProps } = props;
  const { type, editType, editDataSource: options, format } = column;
  const condition = getCondition(type, editType);

  switch (condition) {
    case "number":
      return <InputNumericEditCell format={format} {...inputProps} />;
    case "date":
    case "datetime":
      return <InputDateEditCell dateFormat={format} {...inputProps} />;
    case "boolean":
    case "checkbox":
      return <BoolEditCell {...inputProps} />;
    case "combo":
      return <ComboEditCell options={options} {...inputProps} />;
    case "string":
    default:
      return <InputEditCell {...inputProps} />;
  }
};
