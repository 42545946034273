import { useContext, useMemo } from "react";
import { useSettings } from "./use-settings";
import { useData } from "./use-data";
import { useColumns } from "./use-columns";
import { areArraysSame, mergeColumnArrays } from "../utils/compare-arrays";
import { toast } from "react-toastify";
import { showErrorToast } from "../../../../../echo-error-toast";
import UserContext from "../../../../../../../user-context";

const mapAndSortColumns = (arr1, arr2) =>
  arr1
    .map((c1) => {
      const f = arr2.find((c2) => c1.name === c2.name);
      if (f) return { ...c1, ...f };
      return c1;
    })
    .sort((a, b) => a.order - b.order);

export const useDatagrid = (props) => {
  const { userId, companyId } = useContext(UserContext);
  const { id, name, context, dataSource, onRowEdit, onRowAdd } = props;
  const { resolvedColumns } = useColumns(props);

  const [settings, setSettings] = useSettings(id, dataSource);

  const args = [context, dataSource, settings, name];
  const { state, onChange, refreshData } = useData(...args);

  const onFilterChange = (value) => onChange("filters", value);
  const onPageChange = (value) => onChange("page", value);

  const settingsValue = useMemo(() => {
    const { columns, sorting, itemsPerPage } = settings;
    return { columns, sorting, itemsPerPage };
  }, [settings]);

  const onSortChange = (sortArr) =>
    setSettings({ ...settingsValue, sorting: sortArr }, userId, companyId);

  const onColumnChange = (columns, userId, roleId, type, companyId) =>
    setSettings({ ...settingsValue, columns }, userId, companyId, type);

  const onItemsPerPageChange = (itemsPerPage) =>
    setSettings({ ...settingsValue, itemsPerPage }, userId, companyId);

  const columns = useMemo(
    () =>
      settings.columns.length > 0
        ? mapAndSortColumns(settings.columns, resolvedColumns)
        : mapAndSortColumns(state.columns, resolvedColumns),
    [settings.columns, state.columns, resolvedColumns],
  );

  const updateModel = () => {
    const { columns: dataColumns } = state;
    const { columns: settingsColumns } = settings;
    if (!areArraysSame(dataColumns, settingsColumns)) {
      const merged = mergeColumnArrays(settingsColumns, dataColumns);

      onColumnChange(merged);
    } else {
      toast.info("Model is up to date.");
    }
  };

  const handleRowAdd = async (row) => {
    try {
      await onRowAdd(row);
    } catch (e) {
      showErrorToast(e);
    } finally {
      await refreshData();
    }
  };

  const handleRowEdit = async (row) => {
    try {
      await onRowEdit(row);
    } catch (e) {
      showErrorToast(e);
    } finally {
      await refreshData();
    }
  };

  const api = {
    onColumnChange,
    onPageChange,
    updateModel,
    refreshData,
    onRowEdit: handleRowEdit,
    onRowAdd: handleRowAdd,
    onSortChange: (val) => {
      onSortChange(val);
      onPageChange(0);
    },
    onItemsPerPageChange: (val) => {
      onItemsPerPageChange(val);
      onPageChange(0);
    },
    onFilterChange: (val) => {
      onFilterChange(val);
      onPageChange(0);
    },
  };

  return {
    state: {
      ...state,
      data: state.data,
      columns,
      settings: {
        ...settings,
        columns,
        itemsPerPage: { value: settings.itemsPerPage },
      },
    },
    api,
  };
};
