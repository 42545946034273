import PropTypes from "prop-types";
import {
  shouldForwardProp,
  IconButton,
  chakra,
  Tooltip,
} from "@chakra-ui/react";
import { ArrowUpIcon, ArrowDownIcon } from "@chakra-ui/icons";
import ColumnResizer from "../resizer/resizer";
import { forwardRef, useCallback, useContext, useMemo, useRef } from "react";
import { ContextMenu } from "@echo/ui";
import UserContext from "../../../../../../../../user-context";
import { useContextMenu } from "@echo/ui/src/lib/components/context-menu/hooks/useContextMenu";

const DataGridHeaderCell = forwardRef(
  (
    {
      children,
      sort,
      onSortChange,
      width,
      contentDimensions,
      onSizeChange,
      enableResizer,
      draggable,
      onDragStart,
      onDragEnd,
      onDrag,
      onColumnManagerOpen,
      onColumnSettingsOpen,
      refreshDatagrid,
      fontSize,
      clearFilters,
      tooltip,
    },
    ref,
  ) => {
    const {
      openContextMenu,
      closeContextMenu,
      isContextMenuOpened,
      clickPosition,
    } = useContextMenu();

    const { isSuperuser, isSourceContextSuperuser } = useContext(UserContext);

    const Box = chakra("td", {
      shouldForwardProp: (propName) =>
        propName === "width" || shouldForwardProp(propName),
      baseStyle: { width: () => undefined },
    });

    const columnRef = useRef();

    const nodeRef = ref || columnRef;

    const handleColumnSizeChange = (newSize) => {
      if (nodeRef.current) {
        nodeRef.current.setAttribute("draggable", "false");
        if (nodeRef.current.nextSibling) {
          nodeRef.current.nextSibling.setAttribute("draggable", "false");
        }

        const newWidth = newSize < 0 ? 0 : newSize;
        nodeRef.current.width = `${newWidth}px`;
        nodeRef.current.style.width = `${newWidth}px`;
        nodeRef.current.style.minWidth = `${newWidth}px`;
        nodeRef.current.style.maxWidth = `${newWidth}px`;
      }
    };

    const handleOnColumnManagerOpen = useCallback(() => {
      if (typeof onColumnManagerOpen === "function") {
        onColumnManagerOpen();
      }
    }, [onColumnManagerOpen]);

    const handleColumnSettings = (e) => {
      onColumnSettingsOpen(e);
    };

    const contextMenuActions = useMemo(() => {
      if (isSuperuser || isSourceContextSuperuser)
        return [
          { label: "Manage columns", action: handleOnColumnManagerOpen },
          { label: "Clear filters", action: clearFilters },
          { label: "Advanced column settings", action: handleColumnSettings },
          { label: "Refresh data", action: refreshDatagrid },
        ];
      else
        return [
          { label: "Manage columns", action: handleOnColumnManagerOpen },
          { label: "Clear filters", action: clearFilters },
        ];
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuperuser, isSourceContextSuperuser]);

    return (
      <Tooltip
        isDisabled={!tooltip}
        label={children}
        placement="top"
        padding="2px 4px"
        openDelay={1000}
      >
        <Box
          ref={nodeRef}
          draggable={draggable}
          onDragStart={onDragStart}
          onDragEnd={onDragEnd}
          onDrag={onDrag}
          boxSizing="border-box"
          onClick={onSortChange}
          display="table-cell"
          position="relative"
          as="th"
          fontSize={fontSize}
          width={width || "auto"}
          minWidth={width || "auto"}
          maxWidth={width || "auto"}
          borderRight="1px solid black"
          _hover={{ cursor: "pointer", fontWeight: "bold" }}
          background={"#ccc"}
        >
          {isContextMenuOpened && (
            <Box position="fixed" zIndex="20">
              <ContextMenu
                isOpen={isContextMenuOpened}
                onClose={closeContextMenu}
                actions={contextMenuActions}
                clickPosition={clickPosition}
              />
            </Box>
          )}
          <Box
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            overflow="hidden"
            padding="2px 5px"
            as="div"
            height="100%"
            width="100%"
            onContextMenu={openContextMenu}
          >
            {children}
            {sort && (
              <IconButton
                size="xs"
                height="22px"
                isRound
                variant="ghost"
                onClick={onSortChange}
              >
                {sort === "asc" ? (
                  <ArrowUpIcon fontSize="small" size="small" />
                ) : (
                  <ArrowDownIcon fontSize="small" size="small" />
                )}
              </IconButton>
            )}
            {enableResizer && (
              <ColumnResizer
                columnWidth={
                  typeof width === "number"
                    ? width
                    : typeof width === "string" &&
                        width.includes("px") &&
                        !isNaN(width.toString().replace("px", ""))
                      ? Number(width.replace("px", ""))
                      : 0
                }
                contentDimensions={contentDimensions}
                onSizeChange={handleColumnSizeChange}
                commitChange={onSizeChange}
              />
            )}
          </Box>
        </Box>
      </Tooltip>
    );
  },
);

DataGridHeaderCell.displayName = "DataGridHeaderCell";

DataGridHeaderCell.propTypes = {
  children: PropTypes.node,
  small: PropTypes.bool,
  sort: PropTypes.oneOf(["asc", "desc"]),
  onSortChange: PropTypes.func,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  contentDimensions: PropTypes.shape({
    height: PropTypes.number,
    width: PropTypes.number,
  }),
  onSizeChange: PropTypes.func,
  enableResizer: PropTypes.bool,
  onDrag: PropTypes.func,
  draggable: PropTypes.bool,
  onDragStart: PropTypes.func,
  onDragEnd: PropTypes.func,
  onColumnManagerOpen: PropTypes.func,
  onColumnSettingsOpen: PropTypes.func,
  refreshDatagrid: PropTypes.func,
  fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  clearFilters: PropTypes.func,
  tooltip: PropTypes.bool,
};

export default DataGridHeaderCell;
