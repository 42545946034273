import { checkExistingValue } from "../../../services/validation-service";
import { getFieldsByProperty } from "./utils/get-fields-by-property";
import { getInvalidFields } from "./utils/get-invalid-fields";
import { updateComponent } from "./utils/set-fields-invalid";
import { showRequiredError, showUniqueError } from "./utils/show-error";

const getUniqueFields = (component) =>
  getFieldsByProperty(component, "isUnique");

const getRequiredFields = (component) =>
  getFieldsByProperty(component, "isRequired");

const fieldMapper = (el, state) => {
  const name = el.componentProps.name;
  const value = state[name];

  return { name, value };
};
const mapUniqueFields = (arr, state) => arr.map((el) => fieldMapper(el, state));

const getId = (obj) => {
  const idKey = Object.keys(obj).find((k) => k.toLowerCase() === "id");
  return obj[idKey];
};

const checkUniqueFields = async (arr, state, ctx) => {
  const { component } = ctx;
  const { relatedObjectId: objectId } = component;
  const docId = getId(state);

  const fields = mapUniqueFields(arr, state);

  const invalidFields = await checkExistingValue(objectId, docId, fields);

  const mappedInvalidFields = invalidFields.map((i1) =>
    arr.find((i2) => i1.name == i2.componentProps.name),
  );

  if (mappedInvalidFields.length > 0) {
    arr.forEach(showUniqueError);
    updateComponent(component, mappedInvalidFields, ctx);
    return true;
  }

  return false;
};

const checkRequiredFields = async (arr, state, ctx) => {
  const { component } = ctx;
  const invalidFields = await getInvalidFields(arr, state);

  if (invalidFields.length > 0) {
    invalidFields.forEach(showRequiredError);
    updateComponent(component, invalidFields, ctx);
    return true;
  }

  return false;
};

export const validateFormBlock = (block, componentContext) => {
  return {
    definition: block,
    execute: async (staticParams) => {
      const { __model: state } = staticParams;
      const { component } = componentContext;

      const uniqueFields = getUniqueFields(component);
      const requiredFields = getRequiredFields(component);

      const requiredError = await checkRequiredFields(
        requiredFields,
        state,
        componentContext,
      );

      if (uniqueFields.length > 0) {
        const uniqueError = await checkUniqueFields(
          uniqueFields,
          state,
          componentContext,
        );

        if (requiredError || uniqueError) return { __resultCondition: "false" };
      }

      if (requiredError) return { __resultCondition: "false" };
      return { ...staticParams, __resultCondition: "true" };
    },
  };
};
