// @flow

import * as React from "react";
import { Box, Td } from "@chakra-ui/react";
import { ColumnResizer } from "../column-resizer/column-resizer";
import type { Column } from "../../datagrid-v2";

type CellProps = {
  column: Column,
  width: string,
  children?: React.Node,
  style: React.CSSProperties,
  onClick?: (e: any) => void,
};

export const Cell = (props: CellProps): React.Node => {
  const { column, width, children, style, onClick, cellLinesNumber } = props;

  return (
    <Td
      width={width || "auto"}
      minWidth={width || "auto"}
      maxWidth={width || "auto"}
      padding="0"
      position="relative"
      overflow="hidden"
      lineHeight="unset"
      onClick={onClick}
    >
      {typeof children === "string" ? (
        <Box
          height="100%"
          width="100%"
          padding="4px 16px"
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
          style={style}
          dangerouslySetInnerHTML={{ __html: children }}
          noOfLines={cellLinesNumber}
        />
      ) : (
        <Box
          height="100%"
          width="100%"
          padding="4px 16px"
          overflow={"hidden"}
          textOverflow={"ellipsis"}
          style={style}
          noOfLines={cellLinesNumber}
        >
          {children}
        </Box>
      )}

      <ColumnResizer column={column} />
    </Td>
  );
};
