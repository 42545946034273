import { useEffect, useState } from "react";

export const useSelection = (data, callback, selectionMode) => {
  const [selectedItems, setSelectedItems] = useState([]);

  const selectSingle = (id, type) => {
    if (type === "select") {
      if (selectionMode === "single") setSelectedItems([id]);
      else setSelectedItems((prev) => [...prev, id]);
    }

    if (type === "unselect") {
      setSelectedItems((prev) => prev.filter((el) => el !== id));
    }
  };

  const selectMany = (idArr, type) => {
    if (type === "select") setSelectedItems(idArr);
    if (type === "unselect") setSelectedItems([]);
  };

  useEffect(() => {
    if (data.length > 0) {
      setSelectedItems((prev) =>
        prev.filter((id) => data.find((el) => el.id === id)),
      );
    }
  }, [data]);

  useEffect(() => {
    if (callback) {
      callback(selectedItems);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems]);

  return { selectedItems, selectSingle, selectMany };
};
