export const getFieldsByProperty = (component, property) => {
  const { childrenComponents, componentProps } = component;

  if (childrenComponents && childrenComponents.length > 0) {
    return childrenComponents
      .map((cmp) => getFieldsByProperty(cmp, property))
      .flat(Infinity)
      .filter((el) => el);
  }

  if (componentProps[property]) {
    return component;
  }

  return null;
};
