// @flow
import React, { useRef, useState, useCallback, useEffect } from "react";

import { Box, Td, Tooltip, useDimensions } from "@chakra-ui/react";
import type { Column, SortObj } from "../../datagrid-v2";
import { ColumnResizer } from "../column-resizer/column-resizer";
import { useDatagridContext } from "../../hooks/useDatagridContext";
import { FaArrowUpLong, FaArrowDownLong } from "react-icons/fa6";

import { useDragContext } from "../../hooks/useDragContext";

type CellProps = {
  column: Column,
  width: string,
  children: React.ReactNode,
  index?: number,
  onDragOver: (e: any, columnIndex: number) => void,
  onDragEnd: (e: any, columnIndex: number) => void,
  tooltip: boolean,
};

export const HeaderCell = (props: CellProps) => {
  const {
    sort,
    actions: { onSortChange },
    headerFontSize,
  } = useDatagridContext();
  const { onDragOver, onDragEnd, containerDims } = useDragContext();

  const [isDraggable, setIsDraggable] = useState(true);
  const { width, children, column, index, tooltip } = props;

  const columnSort = sort.find((el) => el.name === column.name);

  const ref = useRef();

  const handleDragOver = (e) => {
    if (onDragOver) {
      if (isDraggable) onDragOver(e, index);
    }
  };

  const handleDragEnd = (e) => {
    if (onDragEnd) {
      if (isDraggable) onDragEnd(e, index);
    }
  };

  const handleDrag = useCallback(
    (e) => {
      if (onDragOver) {
        if (isDraggable) {
          if (!containerDims) return;

          const { pageX, pageY } = e;
          const { marginBox } = containerDims;
          const { left, right, top, bottom } = marginBox;

          if (pageX < left || pageX > right || pageY < top || pageY > bottom) {
            onDragOver(e, -1);
          }
        }
      }
    },
    [onDragOver, isDraggable, containerDims],
  );

  const handleWidthChange = (diff: number) => {
    const widthInt = parseInt(width.replace("px", ""));
    const newWidth = widthInt + diff;

    handleColumnSizeChange(newWidth, column.name);
  };

  const handleColumnSizeChange = (value: number, column) => {
    const newWidth = value < 0 ? 0 : value;
    const pxWidth = `${newWidth}px`;
    ref.current.style.width = pxWidth;
    ref.current.style.minWidth = pxWidth;
    ref.current.style.maxWidth = pxWidth;
  };

  useEffect(() => {
    ref.current.style.width = width;
    ref.current.style.minWidth = width;
    ref.current.style.maxWidth = width;
  }, [width]);

  const getMultipleSort = (sort: SortObj[], columnSort: SortObj) => {
    let initialSortObj = { name: column.name, value: "asc" };

    if (!columnSort) return [...sort, initialSortObj];

    if (columnSort.value === "asc")
      return sort.map((el) =>
        el.name === columnSort.name ? { ...el, value: "desc" } : el,
      );

    return sort.filter((el) => el.name !== columnSort.name);
  };

  const getSingleSort = (columnSort: SortObj | void) => {
    if (!columnSort) return [{ name: column.name, value: "asc" }];

    if (columnSort.value === "asc") return [{ ...columnSort, value: "desc" }];

    return [];
  };

  const handleClick = (e) => {
    let newSortArray;

    if (e.ctrlKey) newSortArray = getMultipleSort(sort, columnSort);
    else newSortArray = getSingleSort(columnSort);

    onSortChange(newSortArray);
  };

  return (
    <Tooltip
      isDisabled={!tooltip}
      placement="top"
      padding="2px 4px"
      label={column.displayName || column.name}
      openDelay={1000}
    >
      <Td
        width={width || "auto"}
        minWidth={width || "auto"}
        maxWidth={width || "auto"}
        userSelect="none"
        padding="0"
        draggable={column.name === "checkbox" ? undefined : isDraggable}
        position="relative"
        overflow="hidden"
        onDragOver={column.name === "checkbox" ? undefined : handleDragOver}
        onDragEnd={column.name === "checkbox" ? undefined : handleDragEnd}
        onDrag={column.name === "checkbox" ? undefined : handleDrag}
        onClick={column.name === "checkbox" ? undefined : handleClick}
        cursor="pointer"
        background="#ccc"
        fontWeight="bold"
        lineHeight="unset"
        ref={ref}
      >
        <Box
          height="100%"
          width="100%"
          padding="6px 16px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          gap="5px"
          fontSize={headerFontSize}
        >
          <Box overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
            {children}
          </Box>
          {columnSort && (
            <Box fontSize="12px">
              {columnSort.value === "asc" ? (
                <FaArrowUpLong />
              ) : (
                <FaArrowDownLong />
              )}
            </Box>
          )}
        </Box>
        <ColumnResizer
          column={column}
          preventDraggable={(val: boolean) => setIsDraggable(!val)}
          onSizeChange={
            column.name === "checkbox" ? undefined : handleWidthChange
          }
        />
      </Td>
    </Tooltip>
  );
};
