import PropTypes from "prop-types";
import InputDisplayCell from "./input-display-cell";
import BooleanDisplayCell from "./boolean-display-cell";

const DisplayCell = (props) => {
  switch (props.type || typeof props.value) {
    case "boolean":
      return <BooleanDisplayCell {...props} />;
    case "datetime":
      return <InputDisplayCell {...props} />;
    case "undefined":
    case "bigint":
    case "number":
    case "string":
      return <InputDisplayCell {...props} />;
    default:
      return null;
  }
};

DisplayCell.propTypes = {
  type: PropTypes.oneOf([
    "string",
    "boolean",
    "number",
    "bigint",
    "date",
    "datetime",
  ]),
  value: PropTypes.any,
};

export default DisplayCell;
