import PropTypes from "prop-types";
import { Button, Input } from "@chakra-ui/react";
import { useApiContext } from "../../../../../echo-components/api-context/use-api-context";
import { useEffect, useState } from "react";
import { useResolveProp } from "../../../../../hooks/resolve-prop/use-resolve-prop";
import { showErrorToast } from "../../../../echo-error-toast";
import { withBaseComponent } from "../../../with-base-component";
import { toast } from "react-toastify";

const ButtonComponent = ({
  id,
  name,
  guid,
  nodeId,
  isDisabled,
  designerMode,
  rootProps,
  isVisible = true,
  text = "button",
}) => {
  const { nodeRef, onClick, ...restRootProps } = rootProps;
  const isDisabledState = useResolveProp(isDisabled, true);
  const isVisibleState = useResolveProp(isVisible, true);
  const [isClicked, setIsClicked] = useState(false);
  const [apiContext, componentDefinition] = useApiContext(id, guid, nodeId);
  const [textValue, setTextValue] = useState();

  const handleOnClick = (e) => {
    if (onClick && typeof onClick === "function") {
      Promise.resolve(onClick(e))
        .then(() => setIsClicked(false))
        .catch((err) => {
          console.error(err);
          if (typeof err === "string") toast.error(err);
          else showErrorToast(err);
          setIsClicked(false);
        })
        .finally(() => {
          setIsClicked(false);
        });
    }
  };

  const handleLabelChange = (newText) => {
    const api = apiContext?.api;
    if (api && designerMode) {
      api.updateComponentProperty(
        id,
        { ...componentDefinition.componentProps, text: newText },
        "componentProps",
      );
    }
  };

  useEffect(() => {
    if (typeof text === "function") {
      Promise.resolve(text())
        .then((result) => {
          if (
            typeof result === "object" &&
            Array.isArray(result) &&
            typeof result[0] === "object" &&
            !Array.isArray(result[0]) &&
            Object.keys(result[0]).length > 0
          ) {
            setTextValue(result[0][Object.keys(result[0])[0]]);
          } else if (
            typeof result === "object" &&
            !Array.isArray(result) &&
            Object.keys(result).length > 0
          ) {
            setTextValue(result[Object.keys(result)[0]]);
          } else if (typeof result !== "function" && !Array.isArray(result)) {
            setTextValue(result);
          }
        })
        .catch((err) => {
          showErrorToast({
            reasonTitle: "Error. Cannot process button action",
            location: `${name} button`,
            shortMessage: err.toString().slice(0, 300),
            message: err.toString(),
          });
          console.error(err);
        });
    } else {
      setTextValue(text);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text]);

  return (
    (designerMode ? true : isVisibleState) && (
      <Button
        {...restRootProps}
        isDisabled={designerMode ? false : isDisabledState}
        ref={nodeRef}
        color="primary"
        transition={designerMode ? "none" : ""}
        onClick={handleOnClick}
      >
        {designerMode ? (
          <Input
            value={typeof textValue === "object" ? name || "button" : textValue}
            variant="unstyled"
            onChange={(e) => {
              handleLabelChange(e.target.value);
            }}
          ></Input>
        ) : typeof textValue === "object" ? (
          name || "button"
        ) : (
          textValue
        )}
      </Button>
    )
  );
};

ButtonComponent.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  text: PropTypes.string,
  designerMode: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isVisible: PropTypes.bool,
  guid: PropTypes.string,
  nodeId: PropTypes.string,
  rootProps: PropTypes.any,
};

export default withBaseComponent(ButtonComponent);
