// @flow

import * as React from "react";
import {
  Box,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
  useOutsideClick,
} from "@chakra-ui/react";
import { Calendar } from "./calendar/calendar";
import { useDatePicker } from "./hooks/use-datepicker";
import { useDate } from "./hooks/use-date";
import { format } from "date-fns";
import { useInputSelection } from "./hooks/use-input-selection";
import { useRef } from "react";
import { FaRegCalendarAlt } from "react-icons/fa";

type DatePickerProps = {
  value: Date,
  onChange: () => void,
  dateFormat: string,
  variant: "outline" | "filled" | "flushed" | "unstyled",
  autoFocus?: boolean,
  inputStyles?: any,
};

export const DatePicker = (props: DatePickerProps): React.Node => {
  const { value, dateFormat, variant, size, onChange, inputStyles } = props;
  const { isOpen, onOpen, onClose, onToggle } = useDisclosure();

  const { currentDate, current, prev, next, handleDateChange } =
    useDatePicker();

  const { getSelectionRange } = useInputSelection();

  const { dateTimeValue, handleDate } = useDate(value, dateFormat, onChange);

  const calendarDays = [prev, current, next];

  const handleDateSelect = (date: Date) => {
    const value = format(date, dateFormat || "dd-MM-yyyy");
    handleDate({ date: value, time: "" });
  };

  const handleInputDateChange = (e) => {
    const date = e.target.value;
    handleDate({ date });
  };

  const handleClick = (e) => {
    e.stopPropagation();
    if (e.target && typeof e.target.setSelectionRange === "function") {
      const selectionPos = e.target.selectionStart;
      const { start, end } = getSelectionRange(
        dateTimeValue.date || "",
        selectionPos,
      );
      e.target.setSelectionRange(start, end);
    }
  };

  const popoverRef = useRef(null);
  const containerRef = useRef(null);

  const styleModifier = {
    name: "zIndex",
    enabled: true,
    phase: "main",
    fn({ state }) {
      state.styles.popper["z-index"] = 9999999;
    },
  };

  useOutsideClick({
    ref: containerRef,
    handler: onClose,
  });

  return (
    <Box ref={containerRef}>
      <Popover
        placement="bottom"
        isOpen={isOpen}
        onClose={onClose}
        strategy="fixed"
        initialFocusRef={popoverRef}
        modifiers={[styleModifier]}
      >
        <PopoverTrigger>
          <InputGroup>
            <Input
              value={dateTimeValue.date || ""}
              onChange={handleInputDateChange}
              onClick={handleClick}
              onDoubleClick={(e) =>
                e.target.setSelectionRange(0, e.target.value.length)
              }
              variant={variant || "outline"}
              size={size}
              width="100%"
              padding="2px 4px"
              {...inputStyles}
            />
            <InputRightElement>
              <IconButton
                size="md"
                icon={<FaRegCalendarAlt />}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  onToggle();
                }}
                variant="ghost"
              />
            </InputRightElement>
          </InputGroup>
        </PopoverTrigger>
        <PopoverContent
          ref={popoverRef}
          width="100%"
          style={{ right: "0", fontWeight: "normal", fontSize: "14px" }}
        >
          <PopoverBody>
            {calendarDays && (
              <Calendar
                calendarDays={calendarDays}
                date={currentDate}
                onDateChange={handleDateChange}
                onDateSelected={handleDateSelect}
                selectedDates={[value]}
              />
            )}
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
};
